import axios from "axios";
import React, {
  useEffect,
  useRef,
  useState
} from 'react';
import {
  useDispatch,
  useSelector
} from "react-redux";
import {useNavigate} from "react-router-dom";
import {
  useMobileIdLoginMutation,
  useMobileIdStatusMutation
} from "../../../redux/api.js";
import {
  completeSmartIdLogin,
  failSmartIdLogin,
  selectErrorMessage,
  startSmartIdLogin
} from "../../../redux/slices/smartIdSlice.js";

export const MobileId = () => {
  const navigate = useNavigate();
  const [personal_code, setPersonalCode] = useState('');
  const [phone_number, setPhoneNumber] = useState('');
  const error = useSelector((state) => state.smartId.error);
  const [errorPersonalCode, setErrorPersonalCode] = useState('');
  const [errorPhoneNumber, setErrorPhoneNumber] = useState('');
  const [verificationCode, setVerificationCode] = useState('');
  const [mobileIdLogin, result] = useMobileIdLoginMutation();
  const [mobileIdStatus, resultStatus] = useMobileIdStatusMutation();
  const dispatch = useDispatch();
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const errorMessage = useSelector(selectErrorMessage);
  
  useEffect(() => {
      let timer;
      const maxTimeout = 30000;
      const startTime = Date.now();
      let shouldCheckStatus = true;
      
      const checkVerificationStatus = async () => {
        try {
          if (verificationCode && shouldCheckStatus) {
            const statusResult = await mobileIdStatus(verificationCode);
            
            if (statusResult.data && statusResult.data.message === 'Login successful') {
              clearInterval(timer);
              shouldCheckStatus = false;
              navigate('/logged');
            } else {
              const currentTime = Date.now();
              const elapsedTime = currentTime - startTime;
              console.log("elapsedTime", elapsedTime)
              if (elapsedTime >= maxTimeout) {
                clearInterval(timer);
                shouldCheckStatus = false;
                
                const errorMessage = statusResult.data
                  ? statusResult.data.message
                  : 'Viga sisselogimisel';
                dispatch(failSmartIdLogin(errorMessage));
              }
            }
          }
        } catch (error) {
          console.error('An error occurred:', error);
        }
      };
      
      // Initial check
      checkVerificationStatus();
      
      // Set an interval to check the verification status every 5 seconds
      timer = setInterval(checkVerificationStatus, 5000);
      
      return () => {
        clearInterval(timer);
      };
    },
    [
      verificationCode,
      navigate,
      dispatch,
      mobileIdStatus
    ]
  );
  
  const handleMobileIdLogin = async () => {
    let validationErrors = [];
    
    // Check if personal_code is empty or doesn't match the expected format
    if (!personal_code || !/^\d{11}$/.test(personal_code)) {
      // Handle validation error, e.g., show an error message
      setErrorPersonalCode('Kehtetu isikukood. Sisestage kehtiv 11-kohaline isikukood.');
      setTimeout(() => {
        setErrorPersonalCode('');
      }, 5000);
      validationErrors.push('Kehtetu isikukood. Sisestage kehtiv 11-kohaline isikukood.');
    } else {
      setErrorPersonalCode(''); // Clear the error
    }
    
    // Check if phone_number is empty or doesn't match the expected format
    if (!phone_number || !/^\+372\d{8,}$/.test(phone_number)) {
      // Handle validation error, e.g., show an error message
      setErrorPhoneNumber('Kehtetu telefoninumber. Sisestage kehtiv telefoninumber algusega +372.');
      setTimeout(() => {
        setErrorPhoneNumber(''); // Clear the error after 5 seconds
      }, 5000);
      validationErrors.push('Kehtetu telefoninumber. Sisestage kehtiv telefoninumber algusega +372.');
    } else {
      setErrorPhoneNumber(''); // Clear the error
    }
    
    if (validationErrors.length > 0) {
      // Display all validation errors
      console.error('Validation errors:', validationErrors);
      dispatch(failSmartIdLogin()); // Set loading to false
      return; // Exit early if there are validation errors
    }
    
    // Dispatch the startSmartIdLogin action to set loading to true
    dispatch(startSmartIdLogin());
    
    try {
      const loginResult = await mobileIdLogin({
        phoneNumber: phone_number,
        personalCode: personal_code,
      });
      
      if (loginResult.data) {
        if (loginResult.status >= 300 && loginResult.status < 400) {
          // Handle redirection (status codes 300-399) or any other special handling
          console.log("Received a redirection response, but not redirecting");
        } else {
          // Dispatch the completeSmartIdLogin action with the user data
          dispatch(completeSmartIdLogin(loginResult.data));
          setVerificationCode(loginResult.data.verificationCode);
        }
      } else {
        dispatch(failSmartIdLogin());
      }
    } catch (error) {
      // Handle any exceptions here, e.g., network errors
      dispatch(failSmartIdLogin());
    }
  };
  
  useEffect(() => {
    let timeoutId;
    
    if (error && resultStatus.isError) {
      setShowErrorMessage(true);
      timeoutId = setTimeout(() => {
        setShowErrorMessage(false);
        setVerificationCode('');
        dispatch(failSmartIdLogin());
      }, 5000);
    }
    
    return () => clearTimeout(timeoutId);
  }, [error, resultStatus.isError, dispatch]);
  
  return (
    <div className="smart-id-container">
      <div className="verification-container">
        {verificationCode ? (
          // Render verification code input and other content
          <>
            Sinu kontrollkood
            <div className="verification-input">
              
              <input
                style={{
                  border: 'none',
                  textAlign: 'center',
                  backgroundColor: '#f1f1f1',
                  fontSize: '40px',
                  boxSizing: 'border-box',
                  maxWidth: '100%',
                  width: '100%',
                }}
                type="text"
                placeholder="Verification Code"
                className="verification-code-input"
                value={verificationCode}
                onChange={(e) => setVerificationCode(e.target.value)}
              />
              
            </div>
            <div>Päring on saadetud Sinu seadmesse.</div>
            <div>Võrdle ja sisesta PIN1</div>
          </>
        ) : (
          <div className="input-section">
            <input
              type="text"
              placeholder="Isikukood"
              className="smart-id-input"
              value={personal_code}
              onChange={(e) => setPersonalCode(e.target.value)}
              autoComplete="on"
            />
            {errorPersonalCode && <div style={{ color: 'red', marginBottom: '-24px' }}>{errorPersonalCode}</div>}
            <br/>
            <input
              type="text"
              placeholder="Telefoninumber algusega +372"
              className="smart-id-input mt-2"
              value={phone_number}
              onChange={(e) => setPhoneNumber(e.target.value)}
            />
            {errorPhoneNumber && <div style={{ color: 'red', marginBottom: '-24px' }}>{errorPhoneNumber}</div>}
            <br/>
            <button className="smart-id-button mt-3" onClick={handleMobileIdLogin}>
              SISENE
            </button>
          </div>
        )}
        {error && resultStatus.isError && showErrorMessage && (
          <div className="error-message" style={{ color: 'red' }}>
            {errorMessage || 'Viga sisselogimisel'}
          </div>
        )}
      </div>
    </div>
  );
};
